<template>
  <div>
    <base-dialog
      :show="showFileDialog"
      :form-title="isSuperUser ? 'Upload Report/File' : 'Upload File'"
      icon="mdi-upload"
      text-btn2="Upload"
      v-on:close="close"
      v-on:save="save"
      :hideDefaultBtn="isUploadingFile"
    >
      <template v-slot:form>
        <v-form
          v-if="!isUploadingFile"
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <v-col
            v-if="isSuperUser"
            cols="12"
            class="my-0 py-0"
          >
            <div class="primary--text text--lighten-2 font-weight-bold mb-1">FILE CATEGORY</div>
            <v-checkbox
              v-model="UploadType"
              class="mt-0"
              color="secondary"
              label="Report"
            />
          </v-col>
          <v-col
            v-if="isSuperUser && UploadType"
            cols="12"
            class="my-0 py-0"
          >
            <div class="primary--text text--lighten-2 font-weight-bold mb-1">
              TYPE <span class="h6 primary--text text--lighten-2">*</span>
            </div>
            <v-radio-group
              v-model="type"
              class="mt-0"
              :disabled="!UploadType"
            >
              <v-radio
                label="Detailed [Report]"
                value="report_detailed"
              />
              <v-radio
                label="Summary [Report]"
                value="report_summary"
              />
              <v-radio
                label="Attestation"
                value="attestation"
              />
              <v-radio
                label="Notification"
                value="notification"
              />
            </v-radio-group>
          </v-col>
          <v-col
            cols="12"
            class="my-0 py-0"
          >
            <div class="primary--text text--lighten-2 font-weight-bold mb-1">
              FILE <span class="h6 primary--text text--lighten-2">*</span>
            </div>
            <v-file-input
              v-model="file"
              :rules="[v => !!v || 'This field is required']"
              truncate-length="15"
              chips
              counter
              outlined
              show-size
              dense
            />
          </v-col>
        </v-form>
        <div v-else>
          <div class="d-flex justify-center mb-6 ">
            Uploading..
          </div>
          <div class="d-flex justify-center m-6">
            <v-progress-circular
              :rotate="-90"
              :size="100"
              :width="15"
              :value="uploadProgress"
              color="primary"
            >
              {{ uploadProgress }}
            </v-progress-circular>
          </div>
        </div>
      </template>
    </base-dialog>
  </div>
</template>

<script>
import BaseDialog from '@/components/dialog/BaseDialog'
import UserService from '@/services/user.service'
import FileUploadMixin from '@/mixins/FileUploadMixin'

export default {
  components: {
    BaseDialog
  },
  props: ['showFileDialog'],
  mixins: [FileUploadMixin],
  data() {
    return {
      isUploadingFile: false,
      uploadProgress: 0,
      projectId: null,
      file: null,
      fileId: null,
      valid: true,
      type: null,
      UploadType: false,
      isSuperUser: false,
    }
  },
  mounted() {
    this.projectId = this.$route.params.id
    const profile = UserService.getLocalProfile()
    if (profile) {
      this.isSuperUser = profile.role === 'Superuser'
    }
  },
}
</script>
